<template>
    <main>
        <Navbar />
        <div class="main">
            <div class="container">
                <div class="overlap" :class="{'overlap-fixed': platform == 'mobile', 'overlap-hide': !overLap}">
                    <div class="mt-3" v-if="platform == 'mobile'">
                        <button class="btn btn-info btn-sm btn-block" @click="overLapToggle">
                            <i class="fas fa-filter mr-2"></i>{{ overLap ? 'Fechar filtros' : 'Abrir filtros' }}
                        </button>
                    </div><!-- /mt-3 -->
                    <div class="overlap-content">
                        <div v-show="users.length">
                            <div class="btn-group w-100" role="group" :class="{'mt-3': platform == 'mobile'}">
                                <button type="button" class="btn btn-secondary" @click="cat = '90'" :class="{'active': cat == '90'}" :disabled="cat == '90' || sport > 1">
                                    <i class="fas fa-clock mr-2" v-show="platform == 'desktop'"></i>
                                    <span v-if="platform == 'desktop'">90 minutos</span>
                                    <span v-else>90 min</span>
                                </button>
                                <button type="button" class="btn btn-secondary" @click="cat = 'pt'" :class="{'active': cat == 'pt'}" :disabled="cat == 'pt' || sport > 1">
                                    <i class="fas fa-clock mr-2" v-show="platform == 'desktop'"></i>
                                    <span v-if="platform == 'desktop'">Primeiro tempo</span>
                                    <span v-else>1º tempo</span>
                                </button>
                                <button type="button" class="btn btn-secondary" @click="cat = 'st'" :class="{'active': cat == 'st'}" :disabled="cat == 'st' || sport > 1">
                                    <i class="fas fa-clock mr-2" v-show="platform == 'desktop'"></i>
                                    <span v-if="platform == 'desktop'">Segundo tempo</span>
                                    <span v-else>2º tempo</span>
                                </button>
                            </div><!-- /btn-group -->
                            <div class="row ml-0 mt-3 mb-3 mr-0">
                                <div class="col pl-0" :class="{'d-contents': platform == 'mobile'}">
                                    <select class="form-control" :class="{'mb-3': platform == 'mobile'}" v-model="sport">
                                        <option value="1" :selected="sport == 1">Futebol</option>
                                        <option value="2" :selected="sport == 2">Basquete</option>
                                        <option value="3" :selected="sport == 3">Lutas</option>
                                        <option value="4" :selected="sport == 4">Hóquei</option>
                                        <option value="5" :selected="sport == 5">Voleibol</option>
                                        <option value="6" :selected="sport == 6">Tênis</option>
                                        <option value="7" :selected="sport == 7">E-Sports</option>
                                    </select>
                                </div>
                                <div class="col pl-0" :class="{'d-contents': platform == 'mobile'}">
                                    <div id="virtual-select" :class="{'mb-3': platform == 'mobile'}"></div>
                                </div>
                                <div class="col pl-0 pr-0" :class="{'d-contents': platform == 'mobile'}">
                                    <button class="btn btn-danger btn-block" @click="controlGeneral">
                                        <i class="fas fa-cogs mr-2"></i>Controle geral
                                    </button>
                                </div><!-- /col -->
                            </div><!-- /row -->
                            <div class="row ml-0 mt-3 mb-3 mr-0">
                                <div class="col pl-0" :class="{'d-contents': platform == 'mobile'}">
                                    <input class="form-control" :class="{'mb-3': platform == 'mobile'}" type="text" v-model="search" v-on:keyup.enter="onEnter" placeholder="Buscar opção...">
                                </div>
                                <div class="col pl-0 pr-0" :class="{'d-contents': platform == 'mobile'}">
                                    <button class="btn btn-warning btn-block" @click="save"><i class="fas fa-save mr-2"></i>Salvar alterações</button>
                                </div><!-- /col -->    
                            </div><!-- /row -->
                        </div><!-- /users -->
                        <div class="mt-3 mb-3">
                            <div v-show="loading == true" class="alert alert-warning" role="alert">
                                <div class="alert-heading text-center mb-0">
                                    <div class="spinner-border mr-2 align-middle" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    <span>Carregando, aguarde...</span>
                                </div>
                            </div><!-- /alert-warning -->
                            <div v-show="error.length" class="alert alert-danger" role="alert">
                                <h4 class="alert-heading">Ops, ocorreu um problema!</h4>
                                <hr />
                                <p class="mb-0">{{error}}</p>
                            </div><!-- /alert-danger -->
                        </div>
                    </div>
                </div><!-- /overlap -->
                <div v-show="users.length" :class="{'m-top': platform == 'mobile'}">
                    <div class="mt-3 mb-3" v-show="markets.length">
                        <template v-if="filteredMarkets.length == 0">
                            <div class="alert alert-danger" role="alert">
                                <h4 class="alert-heading">Ops, nenhum resultado!</h4>
                                <hr />
                                <p class="mb-0">Não encontramos nenhuma opção com o nome "<strong>{{search}}</strong>". Verifique o nome e tente novamente!</p>
                            </div><!-- /alert-danger -->
                        </template>
                        <template v-else>
                            <div class="w-100 clearfix" :class="{'desktop': platform == 'desktop'}">
                                <div class="list-group list-group-flush" :style="{height: screen_innerHeight + 'px'}" :class="{'desktop pr-3': platform == 'desktop', 'rounded': !loading}">
                                    <div class="list-group-item" v-for="(item, index) in filteredMarkets" :key="index" :class="{'bg-danger text-white': String(filteredMarkets[index].active) == 'false'}">
                                        <div class="row" :class="{'justify-content-center': platform == 'mobile'}">
                                            <div class="col text-primary" :class="{'col-12 mb-2 text-center': platform == 'mobile', 'd-flex align-items-center text-truncate': platform == 'desktop', 'cursor-pointer': (item.id == 100 || item.id == 101 || item.id == 102)}" @click="toggleShowRow(item.id)">
                                                <div :class="{'text-truncate': platform == 'desktop', 'bg-cat rounded p-1': platform == 'mobile', 'text-white': String(filteredMarkets[index].active) == 'false'}">{{item.label}}</div>
                                            </div>
                                            <div :class="platform == 'desktop' ? 'col-auto' : 'col-12'">
                                                <div class="d-flex flex-row align-items-center">
                                                    <div :class="{'w-50 mb-2 mr-3 mt-1': platform == 'mobile', 'mr-3': platform == 'desktop'}">Tipo</div>
                                                    <div :class="{'w-50': platform == 'mobile'}">
                                                        <select class="form-control form-control-sm" :class="platform == 'desktop' ? 'w-auto' : 'w-100'" v-model="filteredMarkets[index].type">
                                                            <option value="1" :selected="item.type == 1">Reduzir</option>
                                                            <option value="2" :selected="item.type == 2">Aumentar</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div :class="platform == 'desktop' ? 'col-auto' : 'col-12'">
                                                <div class="d-flex flex-row align-items-center">
                                                    <div :class="{'w-50 mb-2 mr-3 mt-1': platform == 'mobile', 'mr-3': platform == 'desktop'}">%</div>
                                                    <div :class="{'w-50': platform == 'mobile'}">
                                                        <input class="form-control form-control-sm" :class="platform == 'desktop' ? 'w-auto' : 'w-100'" type="text" :id="`percent-${index}`" v-model="filteredMarkets[index].percent" v-number-only @paste="noPaste($event)"> 
                                                    </div>
                                                </div>
                                            </div>
                                            <div :class="platform == 'desktop' ? 'col-auto' : 'col-12'">
                                                <div class="d-flex flex-row align-items-center">
                                                    <div :class="{'w-50 mb-2 mr-3 mt-1': platform == 'mobile', 'mr-3': platform == 'desktop'}">Limite</div>
                                                    <div :class="{'w-50': platform == 'mobile'}">
                                                        <input class="form-control form-control-sm w-limit" :class="platform == 'desktop' ? 'w-auto' : 'w-100'" type="text" :id="`limit-${index}`" v-model="filteredMarkets[index].limit" v-number-only @paste="noPaste($event)"> 
                                                    </div>
                                                </div>
                                            </div>
                                            <div :class="platform == 'desktop' ? 'col-auto' : 'col-12'">
                                                <div class="d-flex flex-row align-items-center">
                                                    <div :class="{'w-50 mb-2 mr-3 mt-1': platform == 'mobile', 'mr-3': platform == 'desktop'}">Bloquear</div>
                                                    <div :class="{'w-50': platform == 'mobile'}">
                                                        <select class="form-control form-control-sm" :id="`active-${index}`" :class="platform == 'desktop' ? 'w-auto' : 'w-100'" v-model="filteredMarkets[index].active">
                                                            <option value="true" :selected="item.active == true">Não</option>
                                                            <option value="false" :selected="item.active == false">Sim</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div><!-- /row -->
                                        <template v-if="item.id == 100 || item.id == 101 || item.id == 102">
                                            <div class="card p-2 mt-2 bg-f1f1f1" v-show="showRowID == item.id">
                                                <div class="row align-items-center">
                                                    <div class="text-truncate text-danger" :class="platform == 'desktop' ? 'col' : 'col-12'">Remover escanteios menores que:</div>
                                                    <div :class="platform == 'desktop' ? 'col' : 'col-12 mt-2'">
                                                        <select :id="`blacklist-${index}`" v-model="filteredMarkets[index].blacklist" class="form-control form-control-sm">
                                                            <option v-for="n in 21" :value="n-1" :key="n-1" :selected="item.blacklist == (n-1)">{{n-1}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </div><!-- /div -->
                        </template>
                    </div><!-- /mt-3 mb-3 -->
                </div><!-- /m-top -->
            </div><!-- /container -->
        </div><!-- /main -->
      <Loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true"></Loading>
    </main>
</template>

<script>
import {api} from '../api'
import Navbar from './Navbar'
import Swal from 'sweetalert2'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import 'virtual-select-plugin/dist/virtual-select.min.css'
import 'virtual-select-plugin/dist/virtual-select.min.js'

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

const heightAdjustMobile = 150;
const heightAdjustDesktop = 259;
const heightAdjust = isMobile ? heightAdjustMobile : heightAdjustDesktop;

export default {
    data() {
        return {
            cat: 90,
            sport: 1,
            error: '',
            search: '',
            showRowID: 0,
            overLap: true,
            loading: false,
            isLoading: false,
            screen_innerHeight: window.innerHeight - heightAdjust,
            platform: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ? 'mobile' : 'desktop',
            id: '',
            users: [],
            markets: []
        }
    },
    components: {
        Navbar,
        Loading
    },
    methods: {
        onEnter() {
            if (this.overLap) this.overLap = false;
        },
        save() {
            
            const self = this;
            const regExp = /[a-zA-Z]/g;
            const filter = self.markets.some(item => item.limit.toString().trim().length == 0 || item.percent.toString().trim().length == 0 || regExp.test(item.limit.toString().trim()) || regExp.test(item.percent.toString().trim()));

            if (filter) {
                Swal.fire(
                    'Atenção',
                    'Preencha todos os campos, utilizando apenas números!',
                    'warning'
                );
            } 
            else 
            {

                self.isLoading = true;
                
                api.post('dashboard/rules-salesman', {
                    id: self.id,
                    markets: self.markets
                }).then((response) => {
                    switch(response.data.result) {
                        case 'success':
                            Swal.fire(
                                'Parabéns!',
                                'As alterações foram salvas com sucesso!',
                                'success'
                            );
                        break;
                        default:
                            Swal.fire(
                                'Falha!',
                                response.data.message,
                                'error'
                            );
                    }
                }).catch((error) => {
                    try {
                        Swal.fire(
                            'Falha',
                            self.network_erros[error.status]['message'],
                            'error'
                        );
                    } catch(e) {
                        Swal.fire(
                            'Falha',
                            self.network_erros[408]['message'],
                            'error'
                        );
                    }
                }).finally(() => {
                    self.isLoading = false;
                });
            }
        },
        async controlGeneral() {

            const self = this;
            const periodMap = {0: 0, 1: '90', 2: 'pt', 3: 'st'};

            const { value: formValues } = await Swal.fire({
                title: 'Controle geral',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Alterar',
                html: `
                    <label class="d-block text-left mb-0">Esporte</label>
                    <select id="swal-input1" class="swal2-input w-100">
                        <option value="0">Todos</option>
                        <option value="1">Futebol</option>
                        <option value="2">Basquete</option>
                        <option value="3">Lutas</option>
                        <option value="4">Hóquei</option>
                        <option value="5">Voleibol</option>
                        <option value="6">Tênis</option>
                        <option value="7">E-Sports</option>
                    </select>

                    <label class="d-block text-left mb-0">Período</label>
                    <select id="swal-input2" class="swal2-input w-100" ${self.sport > 1 ? 'disabled' : ''}>
                        <option value="0">Todos</option>
                        <option value="1" ${self.sport > 1 ? 'selected' : ''}>Tempo completo</option>
                        <option value="2">Primeiro tempo</option>
                        <option value="3">Segundo tempo</option>
                    </select>

                    <label class="d-block text-left mb-0">Cotação</label>
                    <select id="swal-input3" class="swal2-input w-100">
                        <option value="1" selected>Reduzir</option>
                        <option value="2">Aumentar</option>
                    </select>

                    <label class="d-block text-left mb-0">Limite</label>
                    <input id="swal-input4" class="swal2-input" value="30" autocomplete="off" spellcheck="false">

                    <label class="d-block text-left mb-0">Bloquear</label>
                    <select id="swal-input5" class="swal2-input w-100">
                        <option value="true" selected>Não</option>
                        <option value="false">Sim</option>
                    </select>

                    <label class="d-block text-left mb-0">Porcentagem</label>
                    <input id="swal-input6" class="swal2-input" value="0" autocomplete="off" spellcheck="false">
                `,
                focusConfirm: false,
                preConfirm: () => {
                    return {
                        tipo: document.getElementById('swal-input3').value,
                        esporte: document.getElementById('swal-input1').value,
                        periodo: document.getElementById('swal-input2').value,
                        limite: document.getElementById('swal-input4').value,
                        bloquear: document.getElementById('swal-input5').value,
                        porcentagem: document.getElementById('swal-input6').value
                    }
                },
                onOpen: () => {
                    document.getElementById("swal-input1").addEventListener("change", (e) => {

                        try {

                            var sport = e.target.value;
                            var el = document.querySelector('#swal-input2');

                            if (el && sport > 1) {
                                el.setAttribute('disabled', true);
                            } else {
                                el.removeAttribute('disabled');
                            }

                            if (sport == 0) sport = 1;

                            return self.sport = sport;
                        } catch(e) {
                            window.console.log(e.message);
                        }
                    });

                    document.getElementById("swal-input4").addEventListener("input", (e) => {
                        return e.target.value = self.formatNumber(e.target.value);
                    });

                    document.getElementById("swal-input6").addEventListener("input", (e) => {
                        return e.target.value = self.formatNumber(e.target.value);
                    });
                }
            });

            if (formValues instanceof Object) {
                
                if (formValues.limite == '' || formValues.porcentagem == '') {
                    Swal.fire(
                        'Atenção!',
                        'Preencha todos os campos!',
                        'warning'
                    );
                }
                else
                {
                    for (let index in self.markets) {
                        let market = self.markets[index];
                        let market_cat = periodMap[formValues.periodo];

                        if (formValues.esporte == 0 && market_cat == 0) {
                            market.type = formValues.tipo;
                            market.limit = formValues.limite;
                            market.active = formValues.bloquear;
                            market.percent = formValues.porcentagem;
                        } else if (formValues.esporte == 0 && market.cat == market_cat) {
                            market.type = formValues.tipo;
                            market.limit = formValues.limite;
                            market.active = formValues.bloquear;
                            market.percent = formValues.porcentagem;
                        } else if (market.sport == formValues.esporte && market_cat == 0) {
                            market.type = formValues.tipo;
                            market.limit = formValues.limite;
                            market.active = formValues.bloquear;
                            market.percent = formValues.porcentagem;                            
                        } else if (market.sport == formValues.esporte && market.cat == market_cat) {
                            market.type = formValues.tipo;
                            market.limit = formValues.limite;
                            market.active = formValues.bloquear;
                            market.percent = formValues.porcentagem;                            
                        }
                    }
                }
            }
        },
        rulesSalesman(id) {

            const self = this;

            if (id == '') {
                self.id = '';
                self.markets = [];
            } else {

                self.id = id;
                self.markets = [];
                self.isLoading = true;

                api.get(`dashboard/rules-users/${id}`).then((response) => {
                    switch(response.data.result) {
                        case 'success':
                            self.markets = self.marketsStructure(response.data.markets);
                        break;
                        default:
                            self.error = response.data.message;
                    }
                }).catch((error) => {
                    try {
                        self.error = self.network_erros[error.status]['message'];
                    } catch(e) {
                        self.error = self.network_erros[408]['message'];
                    }
                }).finally(() => {
                    self.isLoading = false;
                });
            }
        },
        formatNumber(value) {

           value = value.replace(/\D/g,"");  
           value = value.replace(/[0-9]{12}/," ");  
           value = value.replace(/(\d{1})(\d{8})$/,"$1.$2");
           value = value.replace(/(\d{1})(\d{5})$/,"$1.$2");  
           value = value.replace(/(\d{1})(\d{1,2})$/,"$1.$2"); 
        
           return value.replace(',','.');
        },
        noPaste(event) {
            return event.preventDefault();
        },
        marketGetSport(id) {

            var sport = 1;

            if (id >= 1 && id <= 211) {
                sport = 1;
            } else if (id >= 212 && id <= 220) {
                sport = 2;
            } else if (id >= 221 && id <= 223) {
                sport = 3;
            } else if (id >= 224 && id <= 226) {
                sport = 4;
            } else if (id >= 227 && id <= 229) {
                sport = 5;
            } else if (id >= 230 && id <= 232) {
                sport = 6;
            } else if (id >= 233 && id <= 235) {
                sport = 7;
            }

            return sport;
        },
        marketsStructure(markets) {
            return markets.reduce((unique, o) => {
                if(!unique.some(obj => obj.id === o.id && obj.sport === o.sport)) {
                    unique.push(o);
                }
                return unique;
            },[]).map((item) => {
                return {
                    id: item.id,
                    type: item.type,
                    cat: item.cat,
                    limit: item.limit,
                    percent: item.percent,
                    active: item.active,
                    label: item.label,
                    blacklist: item.blacklist ? item.blacklist : 0,
                    sport: item.sport || this.marketGetSport(item.id)
                }
            });
        },
        toggleShowRow(id) {
            const allowedIds = [100, 101, 102];
            if (allowedIds.includes(id)) {
                if (this.showRowID == id) {
                    this.showRowID = 0;
                } else {
                    this.showRowID = id;
                }
            } else {
                this.showRowID = 0;
            }
        },
        overLapToggle() {
            this.overLap = !this.overLap;
        }
    },
    watch: {
        sport() {
            this.cat = 90;
        },
        users(users) {

            var select = document.querySelector('#virtual-select');

            if (users.length) {

                const options = users.map((item) => {
                    return {
                        value: item.id,
                        label: item.nome,
                        description: item.endereco
                    }
                });
                
                select.setOptions(options);
            }
        }
    },
    computed: {
        filteredMarkets() {
            return this.markets.filter((item) => {
                return item.sport == this.sport && item.cat == this.cat && item.label.toLowerCase().trim().includes(this.search.toLowerCase().trim())
            });
        }
    },
    directives: {
        numberOnly: {
            bind: (el) => {
                el.handler = () => {
                    el.value = el.value.replace(/\D/g,"").replace(/[0-9]{12}/," ").replace(/(\d{1})(\d{8})$/,"$1.$2").replace(/(\d{1})(\d{5})$/,"$1.$2").replace(/(\d{1})(\d{1,2})$/,"$1.$2").replace(',','.');
                }
                el.addEventListener('input', el.handler)
            },
            unbind: (el) => {
                el.removeEventListener('input', el.handler)
            }
        }
    },
    mounted() {

        const self = this;

        window.VirtualSelect.init({
            ele: '#virtual-select',
            search: true,
            placeholder: 'Selecione um vendedor',
            noOptionsText: 'Nenhuma vendedor encontrado',
            noSearchResultsText: 'Nenhum resultado encontrado',
            searchPlaceholderText: 'Buscar vendedor...',
            hasOptionDescription: true
        });

        if (document.querySelector('#virtual-select')) {
            document.querySelector('#virtual-select').addEventListener('change', function() {
                if (this.value) {
                    self.overLap = false;
                    self.rulesSalesman(this.value);
                }
            });
        }
}   ,
    created() {

        const self = this;

        self.loading = true;

        api.get('dashboard/users/1').then((response) => {
            switch(response.data.result) {
                case 'success':
                    self.users = response.data.users.sort((a, b) => {
                        return a.nome.localeCompare(b.nome);
                    });
                break;
                case 'nothing_found':
                    self.error = 'Não encontramos nenhum vendedor disponível!';
                break;
                default:
                    self.error = 'Ocorreu um erro, tente novamente!';
            }
        }).catch((error) => {
            try {
                self.error = self.network_erros[error.status]['message'];
            } catch(e) {
                self.error = self.network_erros[408]['message'];
            }
        }).finally(() => {
            self.loading = false;
        });

        window.addEventListener('resize', () => {
            self.platform = this.isMobile();
        });
    }
}
</script>

<style scoped>
.main {
    margin-top: 80px;
}
.d-contents {
    display: contents!important;
}
.form-control.w-auto {
    width: auto!important;
}
.m-top {
    margin-top: 132px;
}
.loading {
    width: 100%;
    height: 100%;
    top: 70px;
    position: fixed;
    z-index: 1031;
    display: flex;
    background-color: #333;
}
.loading .justify-content-center {
    width: 100%;
    align-items: center;
}
.overlap {
    width: 100%;
    height: 100%;
}
.overlap.overlap-fixed {
    position: fixed;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    background-color: #333;
    right: 0;
    left: 0;
    top: 69px;
    z-index: 1;
}
.overlap.overlap-fixed.overlap-hide {
    height: auto!important;
}
.overlap.overlap-fixed.overlap-hide .overlap-content {
    display: none!important;;
}
.w-100.clearfix.desktop .list-group .list-group-item .row .col-auto input[type="text"]{
    max-width: 100px;
}
.w-100.clearfix .list-group.list-group-flush {
    overflow-x: auto;
}
.w-100.clearfix.desktop .list-group.list-group-flush::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}
.w-100.clearfix.desktop .list-group.list-group-flush::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
.w-100.clearfix.desktop .list-group.list-group-flush::-webkit-scrollbar-thumb {
  background: #e1e1e1;
  border: 0px none #ffffff;
  border-radius: .25rem;
}
.w-100.clearfix.desktop .list-group.list-group-flush::-webkit-scrollbar-thumb:hover {
  background: #ffffff;
}
.w-100.clearfix.desktop .list-group.list-group-flush::-webkit-scrollbar-thumb:active {
  background: #ddd;
}
.w-100.clearfix.desktop .list-group.list-group-flush::-webkit-scrollbar-track {
  background: #666666;
  border: 0px none #ffffff;
  border-radius: .25rem;
}
.w-100.clearfix.desktop .list-group.list-group-flush::-webkit-scrollbar-track:hover {
  background: #666666;
}
.w-100.clearfix.desktop .list-group.list-group-flush::-webkit-scrollbar-track:active {
  background: #333333;
}
.w-100.clearfix.desktop .list-group.list-group-flush::-webkit-scrollbar-corner {
  background: transparent;
}
.w-100.clearfix .list-group.list-group-flush .col-12 .bg-cat {
    background-color: #e8e8e8;
}
</style>